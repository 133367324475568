import React from 'react';
import Robot from "./images/robot.png";
import { useNavigate } from 'react-router-dom';
import './Main.css';


const Main = ({ history }) => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/chat');
    };
  
    return (
        <div className='main-container'>
            <div className='top'> 
                <div className='top-text'> 
                    <p className='top-text2'> MEDIBOT은 의약품 정보 제공</p>
                    <p className='top-text2'> 인공지능 챗봇입니다.</p>
                </div>
                <p className='blank'> </p>
                <p className='blank'></p>
                <p className='blank'></p>
            </div>
            <div className="start-button-container">
                {/* <h1 className='title1'>MEDIBOT에게 질문하기</h1> */}
                <p className='title1'>MEDIBOT에게 질문하기</p>
                <button className="start-button" onClick={handleClick}>
                대화 시작
                </button>
            </div>
        </div>
    );
  };
  

export default Main;