import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Main from './Main';
import Chat from './Chating';

function App() {
    return (
      <BrowserRouter>
        <div className='App'>
          <Routes>
            <Route path="/" element={<Main />}></Route>
            <Route path="/chat" element={<Chat />}></Route>
          </Routes>
        </div>
      </BrowserRouter>
      
    );
};

export default App;
