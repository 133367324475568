import React, { useEffect, useState, useRef } from 'react';
import axios from "axios";
import "./chat.css";
import Robot from "./images/robot.png";

const Chating = () => {
    const [messages, setMessages] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null); // 선택된 이미지를 저장할 상태 추가
    const [selectedFile, setSelectedFile] = useState([]); // 선택된 이미지를 저장할 상태 추가
    const [showImages, setShowImages] = useState([]);
    const [reQuestion, setRequestion] = useState(null);
    const [memoryHos, setMemoryHos] = useState(null);

    const photoInput = useRef();


    const sendMessage = () => {
        const message = messageInput;
        if (message.trim() !== '') {
            displayMessage(message, 'user');
            sendMessageToServer(message);
            setMessageInput('');
        }
    };

    // 이미지를 업로드하는 함수
    // const handleImageUpload = (e) => {
    //     const file = e.target.files[0]; // 선택한 파일 가져오기
    //     setSelectedFile(file);
    //     // uploadImage();
    //     encodeFileToBase64(e.target.files[0]);

        
    // };
    const handleImageUpload = (e) => {
        console.log("in handleImageUpload by 36");
        const files = e.target.files; // 선택한 파일들 가져오기
        console.log(files);
        const selectedImages = [];
        let fileURLs = [];
        // let imageUrlLists = [...showImages];
        let imageUrlLists = [];
      
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          
          selectedImages.push(file);

          const currentImageUrl = URL.createObjectURL(files[i]);
          console.log(currentImageUrl);
          imageUrlLists.push(currentImageUrl);  

        }
        
        setShowImages(imageUrlLists);
      
        setSelectedFile(selectedImages);
        // uploadImages();
      };

    
    const uploadImages = () => {
        if (selectedFile && selectedFile.length > 0) {

            let imageUrlLists = [];

            for (let i = 0; i < selectedFile.length; i++) {
                const file = selectedFile[i];
                      
                const currentImageUrl = URL.createObjectURL(selectedFile[i]);
                imageUrlLists.push(currentImageUrl);  
            }
            displayImagesUser(imageUrlLists, 'user');

            const responseMessage = "조금만 기다려주세요~ 사진을 분석중입니다!"
            displayMessage(responseMessage, 'bot2');

        
          var formData = new FormData();
          console.log(selectedFile.length);
          
          for (let i = 0; i < selectedFile.length; i++) {
            // const name = `multipartFile`
            formData.append("multipartFile" , selectedFile[i]);
          }

          setShowImages([]);
          // 서버로 이미지를 업로드하는 API 호출
        //   fetch('http://ec2-15-165-255-245.ap-northeast-2.compute.amazonaws.com:8080/s3/pillImage', {
        //     method: 'POST',
        //     // headers: {
        //     //     'Content-Type': 'application/x-www-form-urlencoded'
        //     //   },
        //     body: formData,
        //   })
        //   axios('http://ec2-15-165-255-245.ap-northeast-2.compute.amazonaws.com:8080/s3/pillImage', {
          axios('http://localhost:8080/s3/pillImage', {
            method: "post", // 다른 옵션도 가능합니다 (post, put, delete, etc.)
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            data: formData ,
            })
            .then(response => {
              // 이미지 업로드 성공 시 처리
              // data에서 이미지 경로를 받아서 처리
              console.log(response);
              const userImageUrls = response.data[0].s3path;

              if(response.data[0].pillImage === null){
                const responseMessage = `전송된 사진 속 알약은 ${response.data[0].pillName}으로 예측됩니다.
해당 알약에 대한 정확한 정보가 없습니다.`;
                displayMessage(responseMessage, 'bot2');
              }
              else{
                  displayImagesBot(response.data, 'bot2');
              }
            })
            .catch(error => {
                const responseMessage = "죄송합니다. 찾을 수 없습니다."
                displayMessage(responseMessage, 'bot2');
            });
      
          setSelectedFile([]);
        }
        else{
            sendMessage();
        }
    };
      

    // const displayImage = (imageUrl) => {
    //     // 이미지 메시지를 채팅에 표시
    //     const imageMessage = <img src={imageUrl} alt="Uploaded" style={{width: '300px'}}/>;
    //     displayMessage(imageMessage, 'user');  
    // };
    const displayImagesUser = (imageUrls, sender) => {
        // 이미지 메시지를 채팅에 표시
        var imageMessage = [];
        for (let i = 0; i < imageUrls.length; i++) {
            console.log(imageUrls[i])
            imageMessage.push(<img src={imageUrls[i]} alt="Uploaded" style={{ width: '300px', height: '300px'}} />);
        }
        displayMessage(imageMessage, 'user2'); 
        // displayMessage(imageMessage, 'user'); 
      };

        const displayImagesBot = (data, sender) => {
        // 이미지 메시지를 채팅에 표시
        for (let i = 0; i < data.length; i++) {
            if(data[i].pillImage != null && data[i].pillName != null && data[i].efcyQesitm != null && data[i].method != null){
                console.log("if");
                var imageMessage = (
                    <div >
                        <div>
                            <img src={data[i].pillImage} alt="Uploaded" style={{ width: '300px', height: '200px'}} />
                            <h2>{data[i].pillName}</h2>
                            <div className='pillInfo'>
                                <p className='pillInfo2'>효능: </p>
                                <p >{data[i].efcyQesitm}</p>
                            </div>
                            <div className='pillInfo'>
                                <p className='pillInfo2'>복용방법: </p>
                                <p >{data[i].method}</p>
                            </div>
                        </div>
                    </div>
                );
                displayMessage(imageMessage, sender); 
            }
            else if (data[i].pillName != null && data[i].efcyQesitm != null && data[i].method != null){
                console.log("else if");
                var imageMessage = (
                    <div >
                        <div >
                            <h2>{data[i].pillName}</h2>
                            <div className='pillInfo'>
                                <p className='pillInfo2'>효과: </p>
                                <p >{data[i].efcyQesitm}</p>
                            </div>
                            <div className='pillInfo'>
                                <p className='pillInfo2'>복용방법: </p>
                                <p >{data[i].method}</p>
                            </div>
                        </div>
                    </div>
                );
                displayMessage(imageMessage, sender); 
            }
            else{
                console.log("else");
                var imageMessage = (
                    <div >
                        <div >
                            <img src={data[i].pillImage} alt="Uploaded" style={{ width: '300px', height: '200px'}} />
                            <h2>{data[i].pillName}</h2>
                            <p>해당 약품에 대한 정확한 정보를 찾을 수 없습니다.</p>
                        </div>
                    </div>
                );
                displayMessage(imageMessage, sender); 
            }
            
        }

    };

    const sendMessageToServer = (message) => {
        // const url = 'http://localhost:8080/question'; 
        // const url = 'http://ec2-54-180-124-60.ap-northeast-2.compute.amazonaws.com:8080/question'; 

        if(reQuestion === 1){
            const url = 'http://localhost:8080/pharmacy';
            // const url = 'http://ec2-15-165-255-245.ap-northeast-2.compute.amazonaws.com:8080/pharmacy';
            axios(url, {
                method: "post", // 다른 옵션도 가능합니다 (post, put, delete, etc.)
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify(message) ,
                withCredentials: true ,
            })
            .then(response => {
                console.log(reQuestion);
                const location = response.data.entity;
                var pharmacies = response.data.answer.pharmacy;
                    
                const renderPharmacy = (
                    <div >
                        <div >
                            <h3 > {location} 근처 약국 목록입니다.</h3>
                            
                            {pharmacies.map((item, index) => (
                                <div >
                                    <ul>
                                        <li key={index}>
                                        <h3>{item.name}</h3>
                                        <p>주소: {item.address}</p>
                                        <p>전화번호: {item.phone}</p>
                                        <a href={item.place_url}>장소 바로가기</a>
                                        </li>
                                    </ul>
                                </div>
                            ))}
                        </div>
                    </div>
                );
                    
                displayMessage(renderPharmacy, 'bot2');
                setRequestion(null);

            })
        }
        else if(reQuestion === 21){
            const url = 'http://localhost:8080/hospital';
            // const url = 'http://ec2-15-165-255-245.ap-northeast-2.compute.amazonaws.com:8080/hospital';
            const hos = {
                place : message,
                category : memoryHos
            }
            axios(url, {
                method: "post", // 다른 옵션도 가능합니다 (post, put, delete, etc.)
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify(message) ,
                withCredentials: true ,
            })
            .then(response => {
                console.log(reQuestion);
                const location = response.data.entity;
                var hospitals = response.data.answer.hospital;

                const renderHospital = (
                    <div >
                        <div
                        >
                            <h3 > {location} 목록입니다.</h3>
                            <ul>
                            {hospitals.map((item, index) => (
                                <li key={index}>
                                <h3>{item.name}</h3>
                                <p>주소: {item.address}</p>
                                <p>전화번호: {item.phone}</p>
                                <a href={item.place_url}>장소 바로가기</a>
                                </li>
                            ))}
                            
                            </ul>
                        </div>
                    </div>
                );

                displayMessage(renderHospital, 'bot2');
                setRequestion(null);
                setMemoryHos(null);
            })
        }
        else if(reQuestion === 22){
            const url = 'http://localhost:8080/hospital';
            // const url = 'http://ec2-15-165-255-245.ap-northeast-2.compute.amazonaws.com:8080/hospital';
            const hos = {
                place : memoryHos,
                category : message
            }
            axios(url, {
                method: "post", // 다른 옵션도 가능합니다 (post, put, delete, etc.)
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify(message) ,
                withCredentials: true ,
            })
            .then(response => {
                console.log(reQuestion);
                const location = response.data.entity;
                var hospitals = response.data.answer.hospital;

                const renderHospital = (
                    <div >
                        <div
                        >
                            <h3 > {location} 목록입니다.</h3>
                            <ul>
                            {hospitals.map((item, index) => (
                                <li key={index}>
                                <h3>{item.name}</h3>
                                <p>주소: {item.address}</p>
                                <p>전화번호: {item.phone}</p>
                                <a href={item.place_url}>장소 바로가기</a>
                                </li>
                            ))}
                            
                            </ul>
                        </div>
                    </div>
                );

                displayMessage(renderHospital, 'bot2');
                setRequestion(null);
                setMemoryHos(null);
            })
        }
        else if(reQuestion === 0){
            const url = 'http://localhost:8080/precaution';
            // const url = 'http://ec2-15-165-255-245.ap-northeast-2.compute.amazonaws.com:8080/precaution';
            axios(url, {
                method: "post", // 다른 옵션도 가능합니다 (post, put, delete, etc.)
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify(message) ,
                withCredentials: true ,
            })
            .then(response => {
                console.log(reQuestion);
                const pillName = response.data.entity;
                const precaution = response.data.answer.precaution;
                const responseMessage = 
                    `${pillName}의 주의사항은 다음과 같습니다.

${precaution}`;
                displayMessage(responseMessage, 'bot2');
                setRequestion(null);
            })
        }
        else if(reQuestion === 3){
            const url = 'http://localhost:8080/sideEffect';
            // const url = 'http://ec2-15-165-255-245.ap-northeast-2.compute.amazonaws.com:8080/sideEffect';
            axios(url, {
                method: "post", // 다른 옵션도 가능합니다 (post, put, delete, etc.)
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify(message) ,
                withCredentials: true ,
            })
            .then(response => {
                console.log(reQuestion);
                const pillName = response.data.entity;
                const sideEffect = response.data.answer.sideEffect;
                const responseMessage = 
                    `${pillName}의 부작용은 다음과 같습니다.

${sideEffect}`;
                displayMessage(responseMessage, 'bot2');
                setRequestion(null);
            })

        }
        else if(reQuestion === 4){
            const url = 'http://localhost:8080/effect';
            // const url = 'http://ec2-15-165-255-245.ap-northeast-2.compute.amazonaws.com:8080/effect';
            axios(url, {
                method: "post", // 다른 옵션도 가능합니다 (post, put, delete, etc.)
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify(message) ,
                withCredentials: true ,
            })
            .then(response => {
                console.log(reQuestion);
                const pillName = response.data.entity;
                const efcyQesitm = response.data.answer.efcyQesitm;
                const responseMessage = 
                    `${pillName}의 효능은 다음과 같습니다.

${efcyQesitm}`;
                displayMessage(responseMessage, 'bot2');
                setRequestion(null);
            })
        }
        else if(reQuestion === 5){
            const url = 'http://localhost:8080/method';
            // const url = 'http://ec2-15-165-255-245.ap-northeast-2.compute.amazonaws.com:8080/method';
            axios(url, {
                method: "post", // 다른 옵션도 가능합니다 (post, put, delete, etc.)
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify(message) ,
                withCredentials: true ,
            })
            .then(response => {
                console.log(reQuestion);
                const pillName = response.data.entity;
                const method = response.data.answer.method;
                const responseMessage = 
                    `${pillName}의 복용법은 다음과 같습니다.

${method}`;
                displayMessage(responseMessage, 'bot2');
                setRequestion(null);
            })
        }
        else{
            const url = 'http://localhost:8080/question';
            // const url = 'http://ec2-15-165-255-245.ap-northeast-2.compute.amazonaws.com:8080/question';
            axios(url, {
                method: "post", // 다른 옵션도 가능합니다 (post, put, delete, etc.)
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify(message)
            })
            // fetch(url, {
            //     method: 'POST',
            //     headers: {
            //         'Content-Type': 'application/json',
            //     },
            //     body: message ,
            // })
            // .then(response =>response.json())
            // .then(data => {
            .then(response => {
                console.log(response)
                let entities = [];
                let responseMessage = "";

                console.log(response.data.intent);
    
                if (response.data.intent === 0) {
                    if(response.data.entity === null){
                        const renderPharmacy = "어떤 의약품의 주의사항을 알고 싶으신가요?";
                        setRequestion(response.data.intent);
                        displayMessage(renderPharmacy, 'bot2');
                    }
                    else if(response.data.answer.precaution === null){
                        const renderPharmacy = response.data.entity+"의 주의사항에 대한 정보를 찾을 수 없습니다."
                        displayMessage(renderPharmacy, 'bot2');
                    }
                    else{
                        const pillName = response.data.entity;
                        const precaution = response.data.answer.precaution;
                        responseMessage = 
                            `${pillName}의 주의사항은 다음과 같습니다.
        
${precaution}`;
                        displayMessage(responseMessage, 'bot2');
                    }
                }
                else if (response.data.intent === 1) { // 약국
                    console.log("pharmacy");
                    const location = response.data.entity;
    
                    if(location === null){
                        const renderPharmacy = "어느 지역 주변의 약국을 알고 싶으신가요?";
                        setRequestion(response.data.intent);
                        displayMessage(renderPharmacy, 'bot2');
                    }
                    else{
                        var pharmacies = response.data.answer.pharmacy;
                    
                        const renderPharmacy = (
                            <div >
                                <div >
                                    <h3 > {location} 근처 약국 목록입니다.</h3>
                                    
                                    {pharmacies.map((item, index) => (
                                        <div >
                                            <ul>
                                                <li key={index}>
                                                <h3>{item.name}</h3>
                                                <p>주소: {item.address}</p>
                                                <p>전화번호: {item.phone}</p>
                                                <a href={item.place_url}>장소 바로가기</a>
                                                </li>
                                            </ul>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        );
                    
                        displayMessage(renderPharmacy, 'bot2');
                    }
                    
                }
                else if (response.data.intent === 2) {

                    if(response.data.entity === null){
                        const renderHospital = "죄송합니다. 해당 정보를 찾을 수 없습니다.";
                        displayMessage(renderHospital, 'bot2');
                    }
                    else if(response.data.answer.hospital === 21){
                        const renderHospital = "어느 지역의 " + response.data.entity + " 정보를 알고 싶으신가요?";
                        displayMessage(renderHospital, 'bot2');
                        setRequestion(21);
                        setMemoryHos(response.data.entity);
                    }
                    else if(response.data.answer.hospital === 22){
                        const renderHospital = response.data.entity + "의 어떤 병원 정보를 알고 싶으신가요?";
                        displayMessage(renderHospital, 'bot2');
                        setRequestion(22);
                        setMemoryHos(response.data.entity);
                    }
                    else{
                        const location = response.data.entity;
                        var hospitals = response.data.answer.hospital;
        
                        const renderHospital = (
                            <div >
                                <div
                                >
                                    <h3 > {location} 목록입니다.</h3>
                                    <ul>
                                    {hospitals.map((item, index) => (
                                        <li key={index}>
                                        <h3>{item.name}</h3>
                                        <p>주소: {item.address}</p>
                                        <p>전화번호: {item.phone}</p>
                                        <a href={item.place_url}>장소 바로가기</a>
                                        </li>
                                    ))}
                                    
                                    </ul>
                                </div>
                            </div>
                        );
    
                        displayMessage(renderHospital, 'bot2');
                    }
                    
                }
                else if (response.data.intent === 3) {
                    if(response.data.entity === null){
                        const renderPharmacy = "어떤 의약품의 부작용을 알고 싶으신가요?";
                        setRequestion(response.data.intent);
                        displayMessage(renderPharmacy, 'bot2');
                    }
                    else if(response.data.answer.sideEffect === null){
                        const renderPharmacy = response.data.entity+"의 부작용에 대한 정보를 찾을 수 없습니다."
                        displayMessage(renderPharmacy, 'bot2');
                    }
                    else{
                        const pillName = response.data.entity;
                        const sideEffect = response.data.answer.sideEffect;
                        responseMessage = 
                            `${pillName}의 부작용은 다음과 같습니다.
        
${sideEffect}`;
                        displayMessage(responseMessage, 'bot2');
                    }
                }
                else if (response.data.intent === 4) {
                    if(response.data.entity === null){
                        const renderPharmacy = "어떤 의약품의 효능을 알고 싶으신가요?";
                        setRequestion(response.data.intent);
                        displayMessage(renderPharmacy, 'bot2');
                    }
                    else if(response.data.answer.efcyQesitm === null){
                        const renderPharmacy = response.data.entity+"의 효능에 대한 정보를 찾을 수 없습니다."
                        displayMessage(renderPharmacy, 'bot2');
                    }
                    else{
                        const pillName = response.data.entity;
                        const efcyQesitm = response.data.answer.efcyQesitm;
                        responseMessage = 
                            `${pillName}의 효능은 다음과 같습니다.
        
${efcyQesitm}`;
                        displayMessage(responseMessage, 'bot2');
                    }
                }
                else if (response.data.intent === 5) {
                    if(response.data.entity === null){
                        const renderPharmacy = "어떤 의약품의 복용법을 알고 싶으신가요?";
                        setRequestion(response.data.intent);
                        displayMessage(renderPharmacy, 'bot2');
                    }
                    else if(response.data.answer.method === null){
                        const renderPharmacy = response.data.entity+"의 복용방법에 대한 정보를 찾을 수 없습니다."
                        displayMessage(renderPharmacy, 'bot2');
                    }
                    else{
                        const pillName = response.data.entity;
                        const method = response.data.answer.method;
                        responseMessage = 
                            `${pillName}의 복용법은 다음과 같습니다.
        
${method}`;
                        displayMessage(responseMessage, 'bot2');
                    }
                }
                else if (response.data.intent === 100){
                    responseMessage = response.data.entity;
    
                    displayMessage(responseMessage, 'bot2');
                }
            })
            .catch(error => {
                console.log(error.message);
                const responseMessage = "죄송합니다. 정보를 찾을 수 없습니다."
                displayMessage(responseMessage, 'bot2');
            });
        }
        
    };

    const displayMessage = (message, sender) => {
        const newMessage = { message, sender };
        setMessages(prevMessages => [...prevMessages, newMessage]);
    };

    const handleInputChange = (e) => {
        setMessageInput(e.target.value);
    };

    const messageElements = messages.map((message, index) => (
        <div
            // style={{ display: 'flex', flexDirection: 'column',
            // justifyContent: 'flex-end', marginLeft: 'auto'  }}
            className={`${message.sender}-container`}
            >
            <div className={`image-${message.sender}`} ></div>
            <div
                key={index}
                className={`message ${message.sender}-message`}
            >
                <p>{message.message}</p>
            </div>
        </div>
    ));

    // const firstMessage => ()

    const [messageInput, setMessageInput] = useState('');

    const handleClick = () => {
        photoInput.current.click();
    };

    const encodeFileToBase64 = (fileBlob) => {
    const reader = new FileReader();
    reader.readAsDataURL(fileBlob);
    return new Promise((resolve) => {
        reader.onload = () => {
            // setSelectedImage(reader.result);
            resolve();
        };
    });
    };

    useEffect(() => {
        setShowImages((current) => current);
    }, [showImages]);
    
    return (
        <div>
            <div className="chat-container">
                {/* <header>
                    <div className="image-bot2" ></div>
                    <h1>MEDIBOT</h1>
                    <input
                        type="file"
                        accept="image/*"
                        className ='image-button'
                        ref={photoInput}
                        onChange={handleImageUpload}
                        style={{display: 'none'}} 
                    />
                </header> */}

                <div className="chat-window" id="chat-window">
                    <div
                    style={{ display: 'flex', flexDirection: 'row',
                    justifyContent: 'flex-start', marginLeft: 'auto',
                    marginBottom: '10px'  }}>
                        <div className="image-bot2" ></div>
                        <div className="message bot2-message">
                            <p>안녕하세요. 무엇이 궁금하시나요?</p>
                        </div>
                    </div>
                    {messageElements}
                </div>
                <div className='preview-container'>
                    {/* 저장해둔 이미지들을 순회하면서 화면에 이미지 출력 */}
                    <p></p>
                    {showImages.map((image) => (
                        <div className="preview">
                            <img src={image} alt="preview-img" style={{ width: '100px', height: '100px'}}/>
                            {/* <Delete onClick={() => handleDeleteImage(id)} /> */}
                        </div>))}
                </div>
                <div className="input-area">
                    <input
                        type="text"
                        id="message-input"
                        placeholder="메시지를 입력하세요"
                        value={messageInput}
                        onChange={handleInputChange}
                        autoComplete='off'
                    />
                    {/* <input
                        type="file"
                        accept="image/*"
                        className ='image-button'
                        onChange={handleImageUpload}
                    /> */}
                    
                    {/* <button onClick={sendMessage} id="send-button">SEND</button> */}
                    <label for="fileSelect">
                        <div class="btn-upload"></div>
                    </label>
                    <input
                    type="file"
                    id='fileSelect'
                    accept="image/*"
                    multiple  // 다중 이미지 선택을 위한 속성 추가
                    onChange={handleImageUpload}
                    // style={{ display: 'none' }}
                    />
                    <button onClick={uploadImages} id="upload-image-button">전송</button>
                </div>
            </div>
        </div>
    );
};


export default Chating;
